<template>
  <div class="home">
    <v-card
      flat
      class="rounded-lg d-flex flex-wrap flex-md-row flex-column align-md-center ps-md-7 pe-md-10 mb-7 px-sm-5 py-md-3 py-sm-5"
      min-height="146"
      :color="$vuetify.breakpoint.xs ? 'accent' : ''"
    >
      <v-card flat color="d-flex align-center black--text primary lighten-5 px-6 me-md-6 info-card my-md-4">
        <div>
          <div class="text-sm-body-2 text-caption">{{ $t('portfolio.evaluation') }}:</div>
          <div class="f28 mt-1">SAR {{ (summery.portfolio.value / 100).toFixed(2) }}</div>
        </div>
        <v-spacer></v-spacer>
        <v-card width="52" height="52" flat class="white d-flex align-center justify-center rounded-circle ms-5">
          <img src="@/assets/icon/portfolio.svg" alt="case" />
        </v-card>
      </v-card>
      <v-card flat color="d-flex align-center black--text yellow lighten-5 px-6 me-md-6 info-card my-4">
        <div>
          <div class="text-sm-body-2 text-caption">{{ $t('home.balance.wallet') }}:</div>
          <div class="f28 mt-1">SAR {{ (summery.wallet.total_balance / 100).toFixed(2) }}</div>
        </div>
        <v-spacer></v-spacer>
        <v-card width="52" height="52" flat class="white d-flex align-center justify-center rounded-circle ms-5">
          <img src="@/assets/icon/wallet.svg" alt="case" />
        </v-card>
      </v-card>
      <v-spacer></v-spacer>
      <BtnDeposit />
    </v-card>
    <div class="flex-container">
      <Composition />
      <Target />
    </div>
    <Performance v-if="load" />
  </div>
</template>

<script>
import BtnDeposit from '../components/wallet/BtnDeposit.vue';
import Composition from '../components/home/Composition.vue';
import Target from '../components/home/Target.vue';
import Performance from '../components/home/Performance.vue';

export default {
  components: {
    BtnDeposit,
    Composition,
    Target,
    Performance,
  },
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      load: false,
    };
  },
  mounted() {
    this.$store.dispatch('getSummary').finally(() => {
      setTimeout(() => {
        this.load = true;
      }, 100);
    });
  },
  methods: {},
  computed: {
    summery() {
      return this.$store.getters.summaryList;
    },
  },
  destroyed() {
    this.$store.dispatch('setPortfolio');
    this.$store.dispatch('setSummary');
    this.$store.dispatch('getPlanList');
  },
};
</script>

<style lang="scss">
.home {
  .info-card {
    height: 94px;
    border-radius: 10px;
    width: 100%;
    max-width: 440px;
  }
  .flex-container {
    display: flex;
    justify-content: center;
  }
  .v-btn--example {
    bottom: 0;
    right: 0;
    position: fixed;
    margin: 0 60px 26px 0;
  }
  .circle-light {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 52px;
    min-width: 52px;
    border-radius: 50% !important;
    background: rgba(255, 255, 255, 0.5);
  }
  .circle-small {
    display: inline-block;
    margin-right: 12px;
    width: 14px;
    height: 14px;
    border-radius: 50% !important;
  }
  .v-progress-circular__underlay {
    stroke: rgba(214, 214, 214, 0.1);
  }
  .clear .v-progress-circular__underlay {
    stroke: initial;
  }
  @media screen and (max-width: 1440px) {
    .info-card {
      width: inherit;
      max-width: inherit;
    }
  }
  @media screen and (max-width: 999px) {
    .flex-container {
      flex-wrap: wrap;
    }
  }
  @media screen and (max-width: 599px) {
    .f28 {
      font-size: 24px;
    }
  }
}
</style>
