<template>
  <div>
    <div class="d-flex flex-column align-center text-center" v-if="!data.positions">
      <img height="300" src="@/assets/icon/noStock.svg" alt="no data" />
      <div style="max-width: 370px" class="f18 black--text font-weight-bold mb-6">{{ $t('home.target.noDataPlan') }}</div>
      <v-btn depressed color="primary" width="180" height="52" to="/portfolio/plan">{{ $t('btn.plan') }}</v-btn>
    </div>
    <v-data-table v-else class="d-sm-block d-none" hide-default-header :items="data.positions" hide-default-footer :mobile-breakpoint="900">
      <template v-slot:header>
        <thead class="v-data-table-header" style="height: 52px">
          <tr>
            <th v-for="i in $t('home.target.tablePlan')" :key="i">{{ i }}</th>
          </tr>
        </thead>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item, i) in items" :key="i" style="height: 64px" class="black--text">
            <td class="text-body-1 text-no-wrap" style="min-width: 100px">
              <span class="symbol_card">{{ item.security.exchange_symbol }}</span>
            </td>
            <td class="text-body-1 font-weight-bold">{{ item.security.company_name }}</td>
            <td class="text-body-1">{{ item.security.market_sector.name }}</td>
            <td class="text-body-1">{{ item.percent / 100 }}%</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <div class="d-sm-none accent">
      <div v-for="item in data.positions" :key="item.id">
        <v-card flat class="d-flex align-center pa-3 black--text rounded-lg mb-2" min-height="58">
          <div class="font-weight-bold text-truncate">
            <span class="symbol_card me-2">{{ item.security.exchange_symbol }}</span>
            {{ item.security.company_name }}
          </div>
          <v-spacer></v-spacer>
          <div class="font-weight-bold ms-3">{{ item.percent_updated / 100 }}%</div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    data() {
      return this.$store.getters.planList;
    },
  },
};
</script>

<style lang="scss" scoped>
.symbol_card {
  padding: 12px 10px;
  border-radius: 10px;
  font-weight: 700;
  background: var(--v-primary-base);
  color: white !important;
}
@media screen and (max-width: 599px) {
  .symbol_card {
    display: inline-block;
    font-size: 14px;
    padding: 6px 8px;
    border-radius: 6px;
  }
}
</style>
