<template>
  <div>
    <div class="d-flex flex-column align-center text-center" v-if="!data.results">
      <img height="300" src="@/assets/icon/noStock.svg" alt="no data" />
      <div style="max-width: 370px" class="f18 black--text font-weight-bold mb-6">{{ $t('home.target.noDataWallet') }}</div>
      <v-btn depressed color="primary" width="180" height="52" to="/portfolio/transactions">{{ $t('btn.wallet') }}</v-btn>
    </div>
    <v-data-table v-else class="d-sm-block d-none" hide-default-header :items="data.results" hide-default-footer :mobile-breakpoint="900">
      <template v-slot:header>
        <thead class="v-data-table-header" style="height: 52px">
          <tr>
            <th :class="j == 2 || j == 6 ? 'column' : ''" v-for="(i, j) in $t('home.target.tableTrans')" :key="i">{{ i }}</th>
          </tr>
        </thead>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item, i) in items" :key="i" style="height: 64px" class="black--text">
            <td class="text-body-1 text-no-wrap" style="min-width: 100px">
              <span class="symbol_card">{{ item.security.exchange_symbol }}</span>
            </td>
            <td class="text-body-1 font-weight-bold">
              {{ item.security.company_name }} <span :class="item.action == 'sell' ? 'triangle-bottom' : 'triangle-top'"></span>
            </td>
            <td class="text-body-1 text-capitalize column">{{ item.action }}</td>
            <td class="text-body-1">{{ (item.unit_price / 100).toFixed(2) }}</td>
            <td class="text-body-1">{{ item.count }}</td>
            <td class="text-body-1">{{ ((item.count * item.unit_price) / 100).toFixed(2) }}</td>
            <td class="text-body-1 gray--text column">
              {{ new Date(item.created_at).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <div class="d-sm-none accent">
      <div v-for="item in data.results" :key="item.id">
        <v-card flat class="d-flex align-center pa-3 black--text rounded-lg mb-2" min-height="58">
          <div class="font-weight-bold text-truncate">
            <span class="symbol_card me-3">{{ item.security.exchange_symbol }}</span>
            {{ item.security.company_name }}
          </div>
          <span class="ms-1" :class="item.action == 'sell' ? 'triangle-bottom' : 'triangle-top'"></span>
          <v-spacer></v-spacer>
          <div class="font-weight-bold ms-3 text-no-wrap">SAR {{ item.price / 100 }}</div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    data() {
      return this.$store.getters.portfolioSecuritiesTrans;
    },
  },
};
</script>

<style lang="scss" scoped>
.symbol_card {
  padding: 12px 10px;
  border-radius: 10px;
  font-weight: 700;
  background: var(--v-primary-base);
  color: white !important;
}
@media screen and (max-width: 1440px) {
  .column {
    display: none;
  }
}
@media screen and (max-width: 599px) {
  .symbol_card {
    display: inline-block;
    padding: 6px 8px;
    border-radius: 6px;
    font-size: 14px;
  }
}
</style>
