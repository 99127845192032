<template>
  <div class="home-performance">
    <v-card
      tile
      flat
      id="graf"
      class="mt-5 pa-sm-7 pt-sm-3 black--text rounded-lg"
      :color="$vuetify.breakpoint.xs ? 'accent' : ''"
    >
      <div class="d-flex flex-wrap align-sm-center flex-sm-row flex-column mb-sm-10 mb-6">
        <div class="font-weight-bold text-sm-h5 f18 me-sm-4 mt-sm-4">{{ $t('portfolio.performance') }}</div>
        <v-spacer></v-spacer>
        <div class="d-flex flex-wrap align-start mt-sm-0 mt-6">
          <v-select
            class="field48 mt-sm-3 me-7 d-sm-flex d-none"
            v-model="security"
            :items="selectItems"
            hide-details
            item-text="security.exchange_symbol"
            item-value="security.id"
            @change="getSecurity"
            dense
            outlined
            color="primary"
            height="52"
          ></v-select>
          <div
            class="gray lighten-1 rounded-lg mt-sm-4"
            :class="$vuetify.breakpoint.xs ? 'width100  d-flex justify-space-around' : ''"
            style="padding: 6px; height: 48px"
          >
            <span
              :class="period == item ? 'rounded-lg white' : ''"
              class="link d-inline-block mx-2 text-center pa-2 px-5"
              @click="getPeriod(item)"
              v-for="item in periodList"
              :key="item"
            >
              {{ item }}
            </span>
          </div>
        </div>
      </div>
    </v-card>
    <v-card flat class="d-sm-none rounded-lg mt-5">
      <v-list>
        <v-list-item @click="getSecurity(i.security.id)" v-for="i in selectItems" :key="i.id" class="d-flex px-4">
          <div>{{ i.security.exchange_symbol }}</div>
          <v-spacer></v-spacer>
          <v-icon v-if="security != i.security.id" color="gray lighten-1">mdi-circle-outline</v-icon>
          <v-icon v-else color="primary">mdi-record-circle</v-icon>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { createChart } from 'lightweight-charts';

export default {
  data() {
    return {
      grafic: '',
      chart: '',
      period: this.$route.query.period || '1m',
      periodList: ['1d', '1w', '1m', '1y'],
      security: Number(this.$route.query.security) || 'all',
      selectItems: [{ security: { id: 'all', exchange_symbol: 'All Stocks' } }],
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getPortfolioSecurities');
  },
  methods: {
    async getData() {
      const data = new Object();
      data.period = this.period;
      data.security = this.security;
      await this.$store.dispatch('getPortfolioPerformance', data);
    },
    async getSecurity(i) {
      this.security = i;
      this.$nextTick(() => {
        this.getData();
      });
    },
    async getPeriod(i) {
      this.period = i;
      this.$nextTick(() => {
        this.getData();
      });
    },
    getGraf(data) {
      this.chart = createChart(document.querySelector('#graf'), {
        width: document.querySelector('#graf').offsetWidth - `${this.$vuetify.breakpoint.xs ? 0 : 60}`,
        layout: {
          backgroundColor: this.$vuetify.breakpoint.xs ? '#f9fafd' : '#fff',
          textColor: '#909ab0',
        },
        height: this.$vuetify.breakpoint.xs ? 400 : this.$route.name == 'Portfolio' ? 600 : 400,
        localization: {
          locale: 'en-US',
          priceFormatter: (price) => {
            return 'SAR ' + price.toFixed(2);
          },
        },
        rightPriceScale: {
          scaleMargins: {
            top: 0.02,
          },
        },
        handleScroll: { mouseWheel: false, pressedMouseMove: true },
        handleScale: { axisPressedMouseMove: false, mouseWheel: false, pinch: false },
        grid: {
          vertLines: {
            visible: false,
          },
        },
      });
      // var legend = document.createElement('div');
      // legend.className = 'sma-legend text-body';
      // document.querySelector('#graf').appendChild(legend);

      // function setLegendText(priceValue) {
      //   let val = data[data.length - 1].value;
      //   if (priceValue) {
      //     val = priceValue.toFixed(2);
      //   }
      //   legend.innerHTML = `SAR ${val}`;
      // }
      // setLegendText(data[data.length - 1].value);
      // this.chart.subscribeCrosshairMove((param) => {
      //   setLegendText(param.seriesPrices.get(this.grafic));
      // });
      this.grafic = this.chart.addAreaSeries({
        topColor: 'rgba(0, 146, 188, 0.56)',
        bottomColor: 'rgba(0, 146, 188, 0.04)',
        lineColor: 'rgba(0, 146, 188, 1)',
        lineWidth: 2,
      });
      this.grafic.setData(data);
      this.chart.timeScale().fitContent();
      var toolTipWidth = 90;
      var toolTipHeight = 90;
      var toolTipMargin = 5;
      var toolTip = document.createElement('div');
      toolTip.className = 'floating-tooltip-2';
      document.querySelector('#graf').appendChild(toolTip);
      // update tooltip
      this.chart.subscribeCrosshairMove((param) => {
        if (
          param.point === undefined ||
          !param.time ||
          param.point.x < 0 ||
          param.point.x > document.querySelector('#graf').clientWidth ||
          param.point.y < 0 ||
          param.point.y > document.querySelector('#graf').clientHeight
        ) {
          toolTip.style.display = 'none';
        } else {
          toolTip.style.display = 'block';
          var price = param.seriesPrices.get(this.grafic);
          toolTip.innerHTML = `
            <div>SAR ${price}</div>
            <div>
              ${new Date(param.time * 1000).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  })}
            </div>
          `;
          var coordinate = this.grafic.priceToCoordinate(price);
          var shiftedCoordinate = param.point.x - 55;
          if (coordinate === null) {
            return;
          }
          shiftedCoordinate = Math.max(0, Math.min(document.querySelector('#graf').clientWidth - toolTipWidth, shiftedCoordinate));
          var coordinateY =
            coordinate - toolTipHeight - toolTipMargin > 0
              ? coordinate - toolTipHeight - toolTipMargin
              : Math.max(
                0,
                Math.min(document.querySelector('#graf').clientHeight - toolTipHeight - toolTipMargin, coordinate + toolTipMargin)
              );
          toolTip.style.left = shiftedCoordinate + 'px';
          toolTip.style.top = coordinateY + 'px';
        }
      });
    },
  },
  watch: {
    dataGraf() {
      if (this.grafic) {
        this.grafic.setData(this.dataGraf);
        this.chart.timeScale().fitContent();
      } else {
        setTimeout(() => {
          this.getGraf(this.dataGraf);
        }, 300);
      }
    },
    items() {
      this.selectItems = [];
      for (let i = 0; i < this.items.length; i++) {
        this.selectItems.push(this.items[i]);
      }
      this.selectItems.unshift({ security: { id: 'all', exchange_symbol: 'All Stocks' } });
    },
  },
  computed: {
    dataGraf() {
      return this.$store.getters.portfolioPerformance;
    },
    items() {
      return this.$store.getters.portfolioSecurities;
    },
  },
};
</script>

<style lang="scss">
.home-performance {
  .field48 {
    max-width: 200px !important;
  }
  .v-list-item {
    border-bottom: none;
  }
}
</style>
