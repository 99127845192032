<template>
  <v-card class="mb-8 home-composition" rounded="lg" flat :color="$vuetify.breakpoint.xs ? 'accent' : ''">
    <div class="f18 font-weight-bold pa-sm-7 py-5">{{ $t('home.composition.title') }}</div>
    <div class="composition-content px-sm-3" v-if="!profile.is_questionnaire_completed">
      <v-card
        flat
        tile
        max-width="330"
        width="100%"
        :min-height="$vuetify.breakpoint.xs ? '' : 330"
        class="mb-sm-7 mx-auto"
        :class="$vuetify.breakpoint.xs ? 'accent' : ''"
      >
        <vue-chartist class="empty" type="Pie" :data="{ series: [1] }" :options="pie.options"></vue-chartist>
        <div class="risk">
          <v-progress-circular :rotate="155" :value="20" :size="180" :width="10" color="gray lighten-1" class="clear">
            <v-progress-circular :rotate="235" :value="20" :size="180" :width="10" color="gray lighten-1" class="clear">
              <v-progress-circular :rotate="315" :value="20" :size="180" :width="10" color="gray lighten-1" class="clear">
                <v-card flat tile max-width="100" class="text-center black--text font-weight-bold" color="transparent">
                  {{ $t('home.composition.noGraf') }}
                </v-card>
              </v-progress-circular>
            </v-progress-circular>
          </v-progress-circular>
        </div>
      </v-card>
      <div class="d-flex align-center justify-space-between px-sm-6 pb-5 mt-sm-0 mt-4">
        <div class="gray--text text-body-2 me-3">{{ $t('home.composition.noData') }}</div>
        <v-btn :to="`/question/start?page=${this.$route.path}`" depressed color="primary" width="140" height="52">
          {{ $t('btn.proceed') }}
        </v-btn>
      </div>
    </div>
    <div v-else class="composition-content px-sm-3">
      <v-card
        flat
        tile
        max-width="330"
        width="100%"
        min-height="330"
        class="mb-sm-7 mx-auto"
        :class="$vuetify.breakpoint.xs ? 'accent' : ''"
      >
        <!-- <v-progress-circular :rotate="290" :value="data.stocks || 0" :size="268" :width="30" color="secondary">
          <v-progress-circular :rotate="40" :value="data.bonds || 0" :size="268" :width="30" color="success">
            <v-progress-circular :rotate="155" :value="20" :size="180" :width="10" color="primary lighten-1" class="clear">
              <v-progress-circular
                :rotate="235"
                :value="20"
                :size="180"
                :width="10"
                :color="data.risk_profile != 'conservative' ? 'primary lighten-1' : 'accent'"
                class="clear"
              >
                <v-progress-circular
                  :rotate="315"
                  :value="20"
                  :size="180"
                  :width="10"
                  :color="data.risk_profile == 'aggressive' ? 'primary lighten-1' : 'accent'"
                  class="clear"
                >
                  <v-card flat tile max-width="110" class="text-center gray--text f18 font-weight-black text-capitalize">
                    {{ data.risk_profile }} {{ $t('home.composition.risk') }}
                  </v-card>
                </v-progress-circular>
              </v-progress-circular>
            </v-progress-circular>
          </v-progress-circular>
        </v-progress-circular> -->
        <vue-chartist v-if="pie.data.series.length" type="Pie" :data="pie.data" :options="pie.options"></vue-chartist>
        <vue-chartist class="empty" v-else type="Pie" :data="{ series: [1] }" :options="pie.options"></vue-chartist>
        <div class="risk">
          <v-progress-circular :rotate="155" :value="20" :size="180" :width="10" color="primary lighten-1" class="clear">
            <v-progress-circular
              :rotate="235"
              :value="20"
              :size="180"
              :width="10"
              :color="data.risk_profile != 'conservative' ? 'primary lighten-1' : 'gray lighten-1'"
              class="clear"
            >
              <v-progress-circular
                :rotate="315"
                :value="20"
                :size="180"
                :width="10"
                :color="data.risk_profile == 'aggressive' ? 'primary lighten-1' : 'gray lighten-1'"
                class="clear"
              >
                <v-card flat tile max-width="115" class="text-center gray--text font-weight-bold">
                  {{ $t('home.composition.risk') }}
                </v-card>
              </v-progress-circular>
            </v-progress-circular>
          </v-progress-circular>
        </div>
      </v-card>
      <div v-if="pie.data.series.length">
        <v-divider class="mt-sm-0 mt-4"></v-divider>
        <div class="d-flex flex-wrap flex-sm-row flex-column justify-sm-end justify-sm-space-between px-sm-7 pb-4">
          <div
            :class="j <= 1 ? 'd-flex' : 'd-none'"
            class="align-center me-3 mt-6"
            v-for="(i, j) in data.portfolio.stocks"
            :key="i.exchange_id"
          >
            <v-card flat v-if="j <= 1" height="14" width="14" :class="j ? 'primary lighten-2' : 'error'" class="rounded-circle"></v-card>
            <div class="font-weight-bold black--text px-3">{{ i.exchange_symbol }}</div>
            <div class="gray--text">{{ i.percent / 100 }}%</div>
          </div>
          <div class="d-flex mt-6" v-if="data.portfolio.stocks.length > 2">
            <div class="font-weight-bold black--text text-capitalize pe-3">{{ $t('other') }}</div>
            <div class="gray--text">
              {{
                data.portfolio.stocks
                  .map((i) => i.percent / 100)
                  .splice(2, data.portfolio.stocks.length)
                  .reduce((val, newVal) => val + newVal)
              }}%
            </div>
          </div>
        </div>
      </div>
      <div v-else class="d-flex align-center justify-space-between px-6 pb-5">
        <div class="gray--text text-body-2 me-3">{{ $t('home.composition.noStock') }}</div>
        <v-btn to="/portfolio/plan" depressed color="primary" width="140" height="52">{{ $t('btn.portfolio') }}</v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import VueChartist from 'v-chartist';

export default {
  components: {
    'vue-chartist': VueChartist,
  },
  data() {
    return {
      pie: {
        data: {
          series: [],
        },
        options: {
          height: '330px',
          donut: true,
          donutWidth: 50,
          donutSolid: true,
          startAngle: 0,
          showLabel: false,
        },
      },
    };
  },
  watch: {
    data(val) {
      if (val) {
        for (let i = 0; i < this.data.portfolio.stocks.length; i++) {
          this.pie.data.series.push(this.data.portfolio.stocks[i].percent / 100);
        }
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.summaryList;
    },
    profile() {
      return this.$store.getters.profile;
    },
  },
};
</script>

<style lang="scss">
.home-composition {
  max-width: 498px;
  width: 100%;
  min-height: 517px;
  margin-right: 28px;
  .ct-series-a {
    fill: var(--v-error-base) !important;
  }
  .ct-series-b {
    fill: var(--v-primary-lighten2) !important;
  }
  .ct-series-c {
    fill: #009688 !important;
  }
  .ct-series-d {
    fill: #8dc34a !important;
  }
  .ct-series-e {
    fill: #cddc39 !important;
  }
  .ct-series-f {
    fill: #ffeb3b !important;
  }
  .ct-series-g {
    fill: #ffc107 !important;
  }
  .ct-series-h {
    fill: #ff9800 !important;
  }
  .ct-series-i {
    fill: #ff5722 !important;
  }
  .empty {
    .ct-series-a {
      fill: var(--v-gray-lighten1) !important;
    }
  }
  .risk {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
    left: 0;
    top: 75px;
  }
  @media screen and (max-width: 999px) {
    max-width: initial;
    margin-right: initial;
  }
  @media screen and (max-width: 599px) {
    .v-card {
      min-height: inherit !important;
    }
  }
}
.v-application--is-rtl {
  .home-composition {
    margin-right: inherit;
    margin-left: 28px;
    @media screen and (max-width: 999px) {
      margin-left: initial;
    }
  }
}
</style>
