<template>
  <v-card
    class="mb-8 px-md-9 px-sm-6 py-4"
    rounded="lg"
    flat
    max-width="1026"
    :min-width="$vuetify.breakpoint.xs ? '' : 570"
    width="100%"
    min-height="517"
    :color="$vuetify.breakpoint.xs ? 'accent' : ''"
  >
    <v-tabs background-color="transparent" v-model="tab">
      <v-tabs-slider color="transparent"></v-tabs-slider>
      <v-tab
        v-for="item in $t('home.target.tabs')"
        :key="item"
        class="f18 font-weight-bold text-capitalize gray--text"
        active-class="black--text"
      >
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div class="view-without-scroll" style="max-height: 437px; height: 100%">
          <Positions />
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="view-without-scroll" style="max-height: 437px; height: 100%">
          <Plan />
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="view-without-scroll" style="max-height: 437px; height: 100%">
          <Transactions />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Positions from '@/components/tabs/home/Positions.vue';
import Plan from '@/components/tabs/home/Plan.vue';
import Transactions from '@/components/tabs/home/Transactions.vue';

export default {
  components: {
    Positions,
    Plan,
    Transactions,
  },
  data() {
    return {
      tab: 0,
    };
  },
  mounted() {
    this.$store.dispatch('getPortfolioSecurities');
    this.$store.dispatch('getPlanList');
    this.$store.dispatch('getPortfolioSecuritiesTrans', 1);
  },
};
</script>

<style lang="scss" scoped>
.v-tab:not(:last-child):after {
  position: relative;
  right: -17px;
  content: '';
  display: block;
  width: 2px;
  height: 20px;
  background: var(--v-gray-lighten1);
}
.v-application--is-rtl {
  .v-tab:not(:last-child):after {
    right: initial;
    left: -17px;
  }
}
@media screen and (max-width: 599px) {
  .v-tab:not(:last-child):after {
    position: initial;
    content: none;
  }
}
</style>
